import "react-multi-carousel/lib/styles.css";

export const Experience = () => {
    return (
        <section className="experience" id="experience">
            <div className="container">
                <div className="row">
                <div className="col-12">
                <div className="skill-bx">
                    <h2> Experience </h2>

                    <h3>Cisco - San Jose, California </h3>
                    <h4>Sofware Engineering Intern</h4>
                    <h5>May 2024 - Present (on-site)</h5>
                    <p>○ Contributed towards Cisco's Catalyst SD-WAN Manager, a Network Management System product.</p>
                    <p>○ Backend - Develop an API from scratch enabling advisory notifications with configurable options  to be sent to the customer-side.</p>
                    <p>○ Frontend - Designed the UI for the notifications.</p>
                    <p>○ The feature will be a permanent addition beginning the product’s next release.</p>
                    <p>○ Took initiative as one of the ten Global Intern Ambassadors in all of Cisco, hosting events for all Cisco interns around the globe.</p>
                    <br></br>

                    <h3>The Data Mine - West Lafayette, Indiana</h3>
                    <h4>Head Undergraduate Teaching Assisstant</h4>
                    <h5>December 2023 - Present (on-site)</h5>
                    <h4>Undergraduate Teaching Assisstant</h4>
                    <h5>August 2023 - December 2023 (on-site)</h5>
                    <p>○ Conduct regular office hours, providing one-on-one and group support to students working on R, Python, Bash, and SQL projects.</p>
                    <p>○ Responded to over 500 questions posted by the 1300 students on Piazza (course online forum) during the Spring 2024 semester.</p>
                    <p>○ Proactively create effective solutions for the projects for the Teaching Assistants to utilize.</p>
                    <p>○ Organize and lead weekly meetings to review the TA team's agenda, evaluate performance, and gather feedback from the staff.</p>
                    <br></br>

                    <h3>BoilerMake - West Lafayette, Indiana</h3>
                    <h4>Director</h4>
                    <h5>February 2024 - Present (on-site)</h5>
                    <h4>Executive Board - Sponsorship Committee</h4>
                    <h5>March 2023 - January 2024 (on-site)</h5>
                    <p>○ Club that organizes Purdue’s premier software-oriented hackathon event, spanning 36 hours, with 500+ participants and 10+ sponsors.</p>
                    <p>○ Lead a team of 32 board members to promote the hackathon, coordinate logistics, and secure sponsorships from companies.</p>
                    <p>○ Spearheaded the recruitment process by conducting over 150 interviews to select executive board members.</p>
                    <br></br>

                    <h3>The Data Mine - West Lafayette, Indiana</h3>
                    <h4>Undergraduate Data Science Researcher</h4>
                    <h5>August 2022 - May 2023 (on-site)</h5>
                    <p>○ Upgraded Renzoe Box’s color smart matching program.</p>
                    <p>○ Train advanced algorithms aimed at precise customer skin-tone matching with cosmetic products.</p>
                    <p>○ Worked closely within a 10-member team to efficiently complete assigned tasks, ensuring successful project delivery.</p>
                    <br></br>

                    <h3>Coding Tomorrow Initiative - Fremont, California </h3>
                    <h4>President</h4>
                    <h5>April 2020 - September 2022 (online)</h5>
                    <p>Established and served as the inaugural president of a student-run non-profit organization.</p>
                    <p>Designed curriculum and instructed over 700 students for courses in Scratch, Scratch Jr., C, Java, Python, and Competitive Programming.</p>
                    <p>Managed registration processes, delegated responsibilities to officers, and supervised the creation of the organization's website (irvingtoncti.org).</p>
                    <p>Led a 7-member office team in successfully organizing various events, including fundraisers and an online hackathon.</p>
                    <br></br>

                    <h3>Girls Who Code</h3>
                    <h4>President</h4>
                    <h5>June 2021 - June 2022 (on-site)</h5>
                    <h4>Secretary</h4>
                    <h5>March 2019 - June 2021 (on-site)</h5>
                    <p>○ Allocated tasks to officers based on club needs and served as the primary liaison with the advisor.</p>
                    <p>○ Developed curriculum for coding language classes, and taught the material to club members during weekly club meetings</p>
                    <p>○ Created content and hosted workshops in collaboration with other STEM clubs on campus.</p>
                    <br></br>

                    <h3>Irvington Robotics Academy</h3>
                    <h4>President</h4>
                    <h4>Team Captain</h4>
                    <h5>March 2021 - June 2022</h5>
                    <h5>February 2021 - June 2022</h5>
                    <h4>Outreach Officer</h4>
                    <h5>April 2020 - April 2021</h5>
                    <h4>Team Member</h4>
                    <h5>June 2019 - January 2021</h5>
                    <p>○ Built and programmed robots using C++ to earn points in both autonomous and remote-controlled gameplay.</p>
                    <p>○ Recruited engineers for the club.</p>
                    <p>○ Competed in competitions and formed alliances with other teams. </p>
                    <br></br>

                </div>
                </div>
                </div>
            </div>
            
        </section>
    )
}